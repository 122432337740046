/* eslint-disable @typescript-eslint/no-explicit-any */
import { signal } from '@angular/core';
import { ControllerQuery } from '../lib/websocket.type';

export class WebsocketControllerQuery {
  public controller: string;
  public method: string;

  readonly data = signal<{ [key: string]: any }>({});

  constructor(controller: string, method: string, data?: any) {
    this.controller = controller;
    this.method = method;

    if (data) {
      this.setData(data);
    }
  }

  public clone() {
    return new WebsocketControllerQuery(
      this.controller,
      this.method,
      this.data,
    );
  }

  public getQuery(): ControllerQuery {
    return {
      [this.controller]: {
        method: this.method,
        data: this.data(),
      },
    };
  }

  public updateData(key: string, value: any) {
    this.data.update((data) => {
      data[key] = value;

      return { ...data };
    });
  }

  public setData(data: { [key: string]: any }) {
    this.data.set(data);
  }
}

export const PIN_QUERY = {
  staff: {
    model: 'User',
    query: {
      fields: ['id', 'full_name', 'image'],
      with: [
        'training:user_id,badge_number,badge_type,badge_expiry',
        'personal:user_id,date_of_birth,place_of_birth,address,phone_number,email,emergency_contact_name,emergency_contact_number,ni_number,nationality',
      ],
    },
  },
  contractor_staff: {
    model: 'ContractorStaff',
    query: {
      fields: [
        'id',
        'contractor_id',
        'first_name',
        'last_name',
        'phone',
        'email',
        'nationality',
        'house_name',
        'street',
        'city',
        'date_of_birth',
        'place_of_birth',
        'badge_number',
        'badge_type',
        'badge_expiry',
        'image',
      ],
      with: ['contractor:id,name'],
    },
  },
  qr: {
    model: 'StaffQr',
    query: {
      fields: ['id', 'code', 'staff_id', 'staff_type'],
      with: ['staff'],
      first: true,
    },
  },
};
